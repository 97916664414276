
import React from 'react'
import ReactDOM from 'react-dom'

import Root from './routes'
import { ThemeProvider } from '@krowdy-ui/core'
import theme from 'utils/theme'
import { ApolloProvider } from '@apollo/client'
import client from 'config/client'

import reportWebVitals from './reportWebVitals'

import './index.css'

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <Root />
      </ApolloProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import React, { FC, useCallback, useMemo } from 'react'
import { Card, makeStyles, Typography, Button } from '@krowdy-ui/core'

import Progress from 'components/Progress'
import QABox from 'components/QABox'
import { LaborReferentFragment, UpdateAnswerQuestionMutation, UpdateAnswerQuestionMutationVariables, LaborReferentStepSelectedFragment } from '__generated__/typescript-operations'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { loader } from 'graphql.macro'
import { useApolloClient, useMutation } from '@apollo/client'
import produce from 'immer'

interface DetailProps {
  laborReferent?: LaborReferentFragment;
  laborReferentIds?: Array<Pick<LaborReferentFragment, '_id'> & { percent: number }>;
  disabled: boolean;
}

const UPDATE_ANSWER_QUESTION = loader('./apollo/updateAnswerQuestion.graphql')
const LABOR_REFERENT_STEP_SELECTED_FRAGMENT = loader('./apollo/laborReferentStepSelectedFragment.graphql')

const Detail: FC<DetailProps> = ({ laborReferent, laborReferentIds, disabled }) => {
  const classes = useStyles()
  const client = useApolloClient()
  const history = useHistory()
  const [ updateAnswerQuestion ] = useMutation<UpdateAnswerQuestionMutation, UpdateAnswerQuestionMutationVariables>(UPDATE_ANSWER_QUESTION)
  const { backgroundCheckId } = useParams<{backgroundCheckId: string}>()

  const _handleChange = useCallback((id, value) => {
    updateAnswerQuestion({
      variables: {
        answer         : value as string,
        backgroundCheckId,
        laborReferentId: laborReferent?._id!,
        questionId     : id as string
      }
    })
  }, [ backgroundCheckId, laborReferent?._id, updateAnswerQuestion ])

  const percent = useMemo(() => laborReferentIds?.find(({ _id }) =>
    laborReferent?._id === _id)?.percent || 0,
  [ laborReferent?._id, laborReferentIds ])

  const isCompleted = useMemo(() => {
    if(laborReferentIds)
      return laborReferentIds?.reduce((acc, { percent }) => acc + percent, 0) / laborReferentIds.length === 100

    return false
  }, [ laborReferentIds ])

  const _handleNextReference = useCallback(() => {
    const index = laborReferentIds?.findIndex(({ _id }) => laborReferent?._id === _id) || 0
    if(laborReferentIds)
      history.push(generatePath('/background-check/:backgroundCheckId/detail/:referentId', {
        backgroundCheckId,
        referentId: (laborReferentIds[index + 1] ? laborReferentIds[index + 1] : laborReferentIds[0])._id as string
      }))
  }, [ backgroundCheckId, history, laborReferent?._id, laborReferentIds ])

  const _handleShowDashboard = useCallback(() => {
    history.replace(generatePath('/background-check/:backgroundCheckId', {
      backgroundCheckId
    }))
  }, [ history, backgroundCheckId ])

  const _handleClickQABox = (questionId: string) => () => {
    const questionIndex = laborReferent?.questions.findIndex(({ _id }) =>  _id === questionId)
    const params = {
      fragment: LABOR_REFERENT_STEP_SELECTED_FRAGMENT,
      id      : `LaborReferent:${laborReferent?._id}`
    }
    client.writeFragment({
      ...params,
      data: produce(client.readFragment(params), (draft: LaborReferentStepSelectedFragment) => {
        draft.stepSelected = questionIndex!
      })
    })
  }

  return laborReferent ? (
    <Card className={classes.card} variant='outlined'>
      <div className={classes.body}>
        <Typography color='secondary' variant='h2'>Preguntas para referente laboral</Typography>
        {
          laborReferent.questions.map(({ _id, statement, answer }, index) => (
            <QABox
              _id={_id}
              disabled={disabled}
              index={index}
              key={_id}
              onChange={_handleChange}
              onClick={_handleClickQABox(_id!)}
              question={statement}
              value={answer} />
          ))
        }
      </div>
      <div className={classes.footer}>
        <Progress progress={percent} />
        <div>
          <Button>Cambios guardados</Button>
          {
            isCompleted ? (
              <Button color='primary' onClick={_handleShowDashboard} variant='outlined'>Terminar</Button>
            ) : (
              <Button color='primary' onClick={_handleNextReference} variant='outlined'>Siguiente referente</Button>
            )
          }
        </div>
      </div>
    </Card>
  ) : null
}

const useStyles = makeStyles(({ spacing }) => ({
  answer: {
    width: '100%'
  },
  body: {
    alignItems   : 'center',
    display      : 'flex',
    flex         : 1,
    flexDirection: 'column',
    overflowY    : 'auto',
    width        : '100%'
  },
  card: {
    alignItems   : 'center',
    display      : 'flex',
    flex         : 1,
    flexDirection: 'column',
    padding      : spacing(2.5)
  },
  contentTask: {
    display : 'flex',
    flex    : 1,
    margin  : spacing(3.5),
    overflow: 'auto'
  },
  footer: {
    display       : 'flex',
    justifyContent: 'space-between',
    marginTop     : spacing(2),
    width         : '100%'
  },
  qa: {
    backgroundColor: '#F2F4F7',
    marginTop      : spacing(1.75),
    padding        : spacing(2)
  },
  qaContainer: {
    marginTop: spacing(6.5)
  },
  question: {
    textAlign: 'center'
  },
  root: {
    width: '100%'
  },
  title: {
    alignItems    : 'center',
    display       : 'flex',
    justifyContent: 'space-between'
  }
}), { name: 'Detail' })

export default Detail

import React from 'react'
import clsx from 'clsx'
import { useMutation, useQuery } from '@apollo/client'
import { makeStyles, Typography } from '@krowdy-ui/core'
import { Info as InfoIcon } from '@material-ui/icons'
import { loader } from 'graphql.macro'
import { useHistory, useParams } from 'react-router-dom'

import { GetBackgroundCheckQuery, GetBackgroundCheckQueryVariables, LogoutMutation, LogoutMutationVariables } from '__generated__/typescript-operations'

import Main from 'layouts/Main'

import FinishButton from 'containers/FinishButton'
import HeaderJob from 'containers/HeaderJob'
import Profile from 'containers/Profile'

import BackgroundCheckBody from './BackgroundCheckBody'
import Loading from 'components/Loading'

const GET_BACKGROUND_CHECK = loader('./apollo/getBackgroundCheck.graphql')
const LOGOUT = loader('../../layouts/Main/apollo/logout.graphql')

interface BackgroundCheckParams {
  backgroundCheckId: string;
}

const BackgroundCheck = () => {
  const classes = useStyles()
  const history = useHistory()
  const { backgroundCheckId } = useParams<BackgroundCheckParams>()
  const [ logout ] = useMutation<LogoutMutation, LogoutMutationVariables>(LOGOUT)

  const { data, loading } = useQuery<GetBackgroundCheckQuery, GetBackgroundCheckQueryVariables>(
    GET_BACKGROUND_CHECK,
    {
      onError: (e) => {
        if(e.message.indexOf('Forbidden') !== -1)
          logout()
            .then(() => {
              localStorage.clear()
              sessionStorage.clear()
            })
        else
          history.replace('/not-found')
      },
      variables: {
        backgroundCheckId
      }
    }
  )

  if(loading)
    return (
      <Loading />
    )

  if(!data)
    return (
      <div></div>
    )

  const { getBackgroundCheck: backgroundCheck } = data

  return (
    <Main>
      <div className={classes.grid}>
        <div className={clsx(classes.content)}>
          <HeaderJob
            action={(
              <>
                <InfoIcon color='primary' fontSize='small' />
                <Typography className={classes.marginLeft} variant='body1'>Completa las 2 referencias</Typography>
                <FinishButton className={classes.marginLeft} title='Finalizar tarea' />
                {/* //<Button className={classes.marginLeft} color='primary' variant='contained'>Finalizar tarea</Button> */}
              </>
            )}
            jobId={backgroundCheck.jobId} />
          <BackgroundCheckBody
            backgroundCheck={backgroundCheck} />
        </div>
        <div className={classes.side}>
          <Profile candidateId={backgroundCheck.candidateId} />
        </div>
      </div>
    </Main>
  )
}

const useStyles = makeStyles((theme) => ({
  content: {
    display      : 'flex',
    flexDirection: 'column',
    gridArea     : 'content'
  },
  grid: {
    display            : 'grid',
    flex               : 1,
    gridTemplateAreas  : '\'content side\'',
    gridTemplateColumns: '1fr 332px',
    minHeight          : 0,
    overflowY          : 'hidden'
  },
  marginLeft: {
    marginLeft: theme.spacing(1)
  },
  side: {
    backgroundColor: 'white',
    gridArea       : 'side'
  }
}), { name: 'BackgroundCheckPage' })

export default BackgroundCheck

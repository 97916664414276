import React, { FC } from 'react'
import { makeStyles, LinearProgress, Typography } from '@krowdy-ui/core'
import { CheckCircleOutline } from '@material-ui/icons'
import clsx from 'clsx'

import { PaletteColor } from 'utils/theme'

export interface ProgressProps {
  progress: number;
  center?: boolean;
  check?: boolean
}
const Progress: FC<ProgressProps> = ({ progress, center = false, check = false }) => {
  const classes = useStyles()

  return (
    <div className={clsx({
      [classes.center]: center
    })}>
      <Typography className={classes.progressText}>{progress}% completado</Typography>
      <LinearProgress
        classes={{
          bar        : classes.bar,
          determinate: classes.determinate,
          root       : classes.rootBar
        }} value={progress} variant='determinate' />
      {check && progress === 100 ? (
        <CheckCircleOutline className={classes.checkIcon} color='primary' fontSize='small' />
      ) : undefined}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  bar: {
    backgroundColor: (theme.palette.secondary as PaletteColor)[200],
    borderRadius   : theme.shape.borderRadius
  },
  center: {
    alignItems    : 'center',
    display       : 'flex',
    flexDirection : 'column',
    justifyContent: 'center'
  },
  checkIcon: {
    marginTop: theme.spacing(1.5)
  },
  determinate: {
    backgroundColor: (theme.palette.secondary as PaletteColor)[50],
    borderRadius   : theme.shape.borderRadius
  },
  progressText: {
    color       : theme.palette.grey[600],
    marginBottom: theme.spacing(1)
  },
  rootBar: {
    width: 124
  }
}), { name: 'Progress' })

export default React.memo(Progress)

// import { LOCAL_STORAGE_KEYS } from 'utils/constants'
// import { useEffect } from 'react'
// import Account from 'controllers/Account'

import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import { LOCAL_STORAGE_KEYS } from 'utils/constants'
import { VerifyUserQuery, VerifyUserQueryVariables } from '__generated__/typescript-operations'

const {
  REACT_APP_BASE_FRONT_URL,
  REACT_APP_ACCOUNTS_FRONT_URL
} = process.env

const VERIFY_USER = loader('./apollo/verifyUser.graphql')

export const useLoginRequired = () => {
  useQuery<VerifyUserQuery, VerifyUserQueryVariables>(VERIFY_USER, {
    onCompleted: ({ getUser: user }) => {
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.USER_ID, String(user?._id))
    },
    onError: () => {
      const { pathname } = window.location
      // eslint-disable-next-line max-len
      window.location.href = `${REACT_APP_ACCOUNTS_FRONT_URL}/login?urlRedirect=${REACT_APP_BASE_FRONT_URL}${pathname}&urlCallback=${REACT_APP_BASE_FRONT_URL}/callback`
    }
  })
}


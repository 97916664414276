import React, { FC } from 'react'
import { IconButton, Typography, makeStyles } from '@krowdy-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'

import CurriculumView from './CurriculumView'
import { PaletteColor } from 'utils/theme'

interface CurriculumDrawerProps {
  candidateCV?: string;
  onToggleCV?: () => void;
}

const CurriculumDrawer: FC<CurriculumDrawerProps> = ({ candidateCV, onToggleCV }) => {
  const classes = useStyles()

  if(!candidateCV) return null

  return (
    <>
      <div className={classes.drawerHeaderContent}>
        <Typography variant='info2'>CV</Typography>
        <IconButton onClick={onToggleCV} size='small'>
          <CloseIcon fontSize='small' />
        </IconButton>
      </div>
      <div className={classes.cvContainer}>
        <CurriculumView url={candidateCV}  />
      </div>
    </>
  )
}

const useStyles = makeStyles(({ palette, spacing, shadows }) => ({
  cvContainer: {
    display: 'flex',
    height : '100%'
  },
  drawerHeaderContent: {
    alignItems     : 'center',
    backgroundColor: (palette.secondary as PaletteColor)[10],
    boxShadow      : shadows[1], // `inset 0px -1px 0px ${palette.getContrastText('#E8E8E8')}`,
    display        : 'flex',
    justifyContent : 'space-between',
    padding        : spacing(0.5, 1.5),
    zIndex         : 1
  }
}), { name: 'CurriculumDrawer' })

export default React.memo(CurriculumDrawer)

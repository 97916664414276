import React, { FC, useState } from 'react'
import PDF from 'mgr-pdf-viewer-react'
import clsx from 'clsx'

import { makeStyles } from '@krowdy-ui/styles'
import { CircularProgress, Button } from '@krowdy-ui/core'
import {
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon
} from '@material-ui/icons'

interface PDFViewerProps {
  document: {
    url: string;
  };
  loading?: boolean;
}

const PDFViewer: FC<PDFViewerProps> = ({ document, loading }) => {
  const classes = useStyles()
  const [ scale, setScale ] = useState(1)
  const [ disabled, setDisabled ] = useState({
    inIcon : false,
    outIcon: false
  })

  const _handleLessScale = () => {
    setScale((prevScale) => {
      if(prevScale === 0.25) {
        setDisabled({
          inIcon : false,
          outIcon: true
        })

        return prevScale
      }

      return prevScale - 0.25
    })
  }

  const _handleMoreScale = () => {
    setScale((prevScale) => {
      if(prevScale === 5) {
        setDisabled({
          inIcon : true,
          outIcon: false
        })

        return prevScale
      }

      return prevScale + 0.25
    })
  }

  return (
    <div className={classes.pdfContain}>
      <PDF
        css={classes.previewPdf}
        document={document}
        loader={loading ? loading : <CircularProgress className={classes.loader} />}
        navigation={{
          css: {
            nextPageBtn    : classes.nextPage,
            previousPageBtn: classes.prevPage,
            wrapper        : clsx(classes.wrapper, classes.wrapperPdf)
          }
        }}
        scale={scale} />
      <div className={clsx(classes.wrapper, classes.moreButtons)}>
        <Button disabled={disabled.outIcon} onClick={_handleLessScale} ><ZoomOutIcon className={classes.zoomIcon} /> </Button>
        <Button disabled={disabled.inIcon} onClick={_handleMoreScale}><ZoomInIcon className={classes.zoomIcon} /></Button>
      </div>
    </div>
  )
}

const useStyles = makeStyles({
  loader: {
    bottom  : 0,
    left    : 0,
    position: 'absolute',
    right   : 0,
    top     : 0
  },
  moreButtons: {
    borderLeft   : '2px solid white',
    bottom       : 'calc(66px - 12px)',
    left         : 'calc(50% + 150px)',
    verticalAlign: 'middle'
  },
  nextPage: {
    '& a': {
      color: 'white'
    }
  },
  pdfContain: {
    position: 'relative'
  },
  prevPage: {
    '& a': {
      color: 'white'
    }
  },
  previewPdf: {
    borderRadius: 3,
    position    : 'relative',
    textAlign   : 'start'
  },
  wrapper: {
    background    : 'rgba(0,0,0,0.75)',
    color         : 'white',
    display       : 'flex',
    fontSize      : 18,
    height        : 44,
    justifyContent: 'space-between',
    padding       : 8,
    position      : 'absolute',
    width         : 150,
    zIndex        : 100
  },
  wrapperPdf: {
    bottom       : 'calc(66px - 12px)',
    left         : '50%',
    verticalAlign: 'middle'
  },
  zoomIcon: {
    color        : 'white',
    verticalAlign: 'top'
  }
}, { name: 'PDFViewer' })

export default PDFViewer

import { useMutation, useQuery } from '@apollo/client'
import { Button, Card, CardActions, CardContent, CardHeader, makeStyles, notify, Popover, Typography } from '@krowdy-ui/core'
import { Info as InfoIcon } from '@material-ui/icons'
import { loader } from 'graphql.macro'
import React, { FC, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { BackgroundCheckStatus, FinishMutation, FinishMutationVariables, GetBackgroundCheckStatusQuery, GetBackgroundCheckStatusQueryVariables } from '__generated__/typescript-operations'

interface FinishButtonProps {
  className?: string;
  title: string;
  onOk?: () => void;
}

const GET_BACKGROUND_CHECK = loader('./apollo/getBackgroundCheck.graphql')
const FINISH = loader('./apollo/finish.graphql')

const CODE_ERROR_ABORT = 'CODE_ERROR_ABORT'

const errorMessage = {
  [CODE_ERROR_ABORT]: 'Para finalizar la tarea se debe completar la información de una referencia como mínimo. Caso contrario se debe abandonar.'
}

const FinishButton: FC<FinishButtonProps> = ({ title, onOk, className }) => {
  const classes = useStyles()

  const { backgroundCheckId } = useParams<{ backgroundCheckId: string }>()
  const [ finish, { loading: loadingFinish } ] = useMutation<FinishMutation, FinishMutationVariables>(FINISH)
  const { getBackgroundCheck } = useQuery<GetBackgroundCheckStatusQuery, GetBackgroundCheckStatusQueryVariables>(
    GET_BACKGROUND_CHECK, { variables: { backgroundCheckId } }
  ).data!

  const [ anchorEl, setAnchorEl ] = useState<EventTarget & HTMLButtonElement | null>(null)

  const _handleClick = useCallback(async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    setAnchorEl(e.currentTarget)
  }, [])

  const _handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const _handleClickConfirm = useCallback(async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    try {
      await finish({
        variables: {
          backgroundCheckId
        }
      })

      notify.success('Tarea finalizada')

      if(onOk)
        onOk()
    } catch (error: any) {
      if(error?.message.includes(CODE_ERROR_ABORT))
        notify.error(errorMessage[CODE_ERROR_ABORT], { time: 8000 })
    }

    setAnchorEl(null)
  }, [ backgroundCheckId, finish, onOk ])

  return (
    <>
      <Button
        className={className}
        color='primary'
        disabled={getBackgroundCheck.status === BackgroundCheckStatus.Executed}
        onClick={_handleClick}
        variant='contained'>
        {title}
      </Button>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'center',
          vertical  : 'bottom'
        }}
        id={id}
        onClose={_handleClose}
        open={open}
        transformOrigin={{
          horizontal: 'center',
          vertical  : 'top'
        }}>
        <Card className={classes.card}>
          <CardHeader
            avatar={<InfoIcon color='error' fontSize='small' />}
            className={classes.cardHeader}
            title={'¿Seguro de finalizar tarea?'}
            titleTypographyProps={{
              variant: 'h6'
            }} />
          <CardContent className={classes.cardContent}>
            <Typography variant='body1'>
              Si finalizas esta tarea se generará un reporte con el pago correspondiente, no podrás volver a activar esta tarea.
            </Typography>
          </CardContent>
          <CardActions className={classes.cardActions}>
            <Button color='error' onClick={_handleClose}>Cancelar</Button>
            <Button
              color='error'
              disabled={getBackgroundCheck.status === BackgroundCheckStatus.Executed || loadingFinish}
              onClick={_handleClickConfirm}
              variant='contained'>Finalizar tarea</Button>
          </CardActions>
        </Card>
      </Popover>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    width: 320
  },
  cardActions: {
    display       : 'flex',
    justifyContent: 'flex-end',
    padding       : theme.spacing(2.5)
  },
  cardContent: {
    padding      : theme.spacing(2.5),
    paddingBottom: 0
  },
  cardHeader: {
    padding      : theme.spacing(2.5),
    paddingBottom: 0
  }
}))

export default React.memo(FinishButton)

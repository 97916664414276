import React from 'react'
import ViewError from 'components/ViewError'
import { Redirect, Route, Switch, BrowserRouter as Router } from 'react-router-dom'
import CallbackContainer from 'pages/callback'
import BackgroundCheck from 'pages/background-check'

// import HomePage from 'pages/home'

import PrivateRoute from './PrivateRouter'

const Root = () => (
  <Router>
    <Switch>
      <Route path='/background-check/forbidden' render={() => <ViewError title='Forbidden' />} />
      <PrivateRoute exact path={[ '/background-check/:backgroundCheckId', '/background-check/:backgroundCheckId/detail/:referentId' ]} strict>
        <BackgroundCheck />
      </PrivateRoute>
      <Route component={CallbackContainer} path='/callback' />
      <Redirect
        exact
        from='/'
        strict
        to='/no-found' />
      <Route render={() => <ViewError title='Not-Found' />} />
    </Switch>
  </Router>
)

export default Root

import React, { FC, useMemo } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@krowdy-ui/core'
import { AnimatePresence, motion } from 'framer-motion'
import { generatePath, useHistory, useParams } from 'react-router-dom'

import { BackgroundCheckFragment, BackgroundCheckStatus, LaborReferentFragment } from '__generated__/typescript-operations'
import CardReferent from './CardReferent'

import Detail from 'containers/Detail'

const timeAnimation = .5

const Constants = {
  candidateBarWidth  : 58,
  trackingItemSpacing: 12,
  trackingItemWidth  : 320
}

const detailVariants = {
  close: {
    opacity   : 1,
    transition: {
      duration: timeAnimation
    },
    x: 0,
    y: 0
  },
  invisible: {
    opacity   : 0,
    transition: {
      duration: timeAnimation
    },
    x: 0,
    y: '100%'
  },
  open: ({ index, scrollLeft }: { index: number; scrollLeft: number; }) => ({
    opacity   : 1,
    transition: {
      duration: timeAnimation
    },
    x: -(index * (Constants.trackingItemWidth + Constants.trackingItemSpacing)) + scrollLeft,
    y: 0
  })
}

const variants = {
  enter: {
    opacity: 1,
    y      : 0
  },
  exit: {
    opacity: 0,
    y      : '100%'
  }
}

interface BackgroundCheckBodyProps {
  className?: string;
  backgroundCheck: BackgroundCheckFragment
}

const BackgroundCheckBody: FC<BackgroundCheckBodyProps> = ({ backgroundCheck, className }) => {
  const classes = useStyles()
  const history = useHistory()
  const scroll = React.useRef<HTMLInputElement>(null)
  const [ scrollLeft, setScrollLeft ] = React.useState<number>(0)
  const { referentId, backgroundCheckId } = useParams<{referentId: string; backgroundCheckId: string}>()
  const disabled = useMemo(() => backgroundCheck.status === BackgroundCheckStatus.Executed, [ backgroundCheck.status ])

  const _handleShowDetail = React.useCallback((laborReferent: LaborReferentFragment) => () => {
    if(
      laborReferent._id && !referentId && (
        laborReferent.companyName ||
        laborReferent.email ||
        laborReferent.fullName ||
        laborReferent.jobPosition ||
        laborReferent.phone
      )) {
      if(scroll.current) setScrollLeft(scroll.current.scrollLeft)
      history.push(generatePath('/background-check/:backgroundCheckId/detail/:referentId', {
        backgroundCheckId,
        referentId: laborReferent._id
      }))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ referentId ])

  const laborReferent = useMemo(() =>
    backgroundCheck.laborReferents.find(laborReferent => laborReferent._id === referentId),
  [ backgroundCheck.laborReferents, referentId ])

  const laborReferentIds = useMemo(() =>
    backgroundCheck.laborReferents
      .filter(({ companyName, email, fullName, jobPosition, phone }) => companyName || email || fullName || jobPosition || phone)
      .map(({ _id, questions }) => ({
        _id,
        percent: Math.round(questions.reduce((acc, question) => {
          if(question.answer)
            acc++

          return acc
        }, 0) / questions.length * 100)
      }))
  , [ backgroundCheck.laborReferents ])

  return (
    <div className={clsx(classes.container, className)}>
      { backgroundCheck.laborReferents.map((laborReferent, index) => (
        <motion.div
          animate={referentId ? laborReferent._id === referentId ? 'open' : 'invisible' : 'close'}
          className={clsx(classes.card, {
            [classes.pointer]: !referentId
          })}
          custom={{
            index,
            scrollLeft
          }}
          key={laborReferent._id}
          onClick={_handleShowDetail(laborReferent)}
          variants={detailVariants}>
          <CardReferent
            disabled={Boolean(disabled || laborReferent.disablePayment)}
            index={index}
            key={laborReferent._id}
            laborReferent={laborReferent}
            laborReferentIds={laborReferentIds}
            total={backgroundCheck.laborReferents.length} />
        </motion.div>
      ))}
      <AnimatePresence>
        {
          referentId &&
            <motion.div
              animate='enter'
              className={classes.motionCV}
              exit='exit'
              initial='exit'
              transition={{ duration: .5 }}
              variants={variants}>
              <Detail
                disabled={Boolean(disabled || laborReferent?.disablePayment)}
                laborReferent={laborReferent}
                laborReferentIds={laborReferentIds} />
            </motion.div>
        }
      </AnimatePresence>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    marginRight: theme.spacing(1.5),
    width      : 320
  },
  cardContent: {
    paddingBottom: 0,
    paddingTop   : 0
  },
  cardHeader: {
    display       : 'flex',
    justifyContent: 'center',
    paddingBottom : theme.spacing(1.5),
    paddingTop    : theme.spacing(1.5)
  },
  container: {
    display  : 'flex',
    flex     : 1,
    minHeight: 0,
    overflowY: 'hidden',
    padding  : theme.spacing(1.5),
    position : 'relative'
  },
  flex: {
    alignItems: 'center',
    display   : 'flex',
    height    : '100%'
  },
  marginLeft: {
    marginLeft: theme.spacing(1)
  },
  motionCV: {
    bottom       : 0,
    display      : 'flex',
    height       : '100%',
    paddingBottom: 'inherit',
    paddingRight : theme.spacing(1.5),
    paddingTop   : 'inherit',
    position     : 'absolute',
    right        : 0,
    top          : 0,
    width        : `calc(100% - ${Constants.trackingItemWidth}px - ${Constants.trackingItemSpacing * 2}px)`
    // width        : 500
  },
  pointer: {
    cursor: 'pointer'
  },
  root: {
    display : 'flex',
    flex    : 1,
    overflow: 'hidden'
  }
}), { name: 'BackgroundCheckBody' })

export default BackgroundCheckBody

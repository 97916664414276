import React, { FC, useState, useCallback } from 'react'
import { makeStyles, Typography, Card, TextField } from '@krowdy-ui/core'
import { useDebouncedCallback } from 'use-debounce'
import { Maybe } from '__generated__/typescript-operations'

export interface QABoxProps {
  question: string;
  value: string;
  _id?: Maybe<string>;
  // eslint-disable-next-line no-unused-vars
  onChange?: (_id?: Maybe<string>, debounceValue?: string) => void;
  onClick?: () => void;
  index: number;
  disabled?: boolean;
}

const QABox: FC<QABoxProps> = ({ question, value, _id, onChange = () => {}, onClick = () => {}, index, disabled }) => {
  const classes = useStyles()
  const [ currentValue, setCurrentValue ] = useState(value)

  const debounced = useDebouncedCallback(
    (value) => {
      onChange(_id, value)
    },
    600
  )

  const _handleChangeValue = useCallback((ev) => {
    setCurrentValue(ev.target.value)
    debounced.callback(ev.target.value)
  }, [ debounced ])

  return (
    <div className={classes.qaContainer} id={`question-${_id}`}>
      <div className={classes.question}>
        <Typography variant='h4'>{index + 1}. {question}</Typography>
      </div>
      <Card className={classes.answerContainer}>
        <TextField
          className={classes.answer}
          disabled={disabled}
          label='Respuesta de referente'
          multiline
          onChange={_handleChangeValue}
          onClick={onClick}
          value={currentValue} />
      </Card>
    </div>
  )
}

const useStyles = makeStyles(({ spacing }) => ({
  answer: {
    width: '100%'
  },
  answerContainer: {
    backgroundColor: '#F2F4F7',
    marginTop      : spacing(1.75),
    padding        : spacing(2)
  },
  qaContainer: {
    marginTop: spacing(6.5),
    width    : '100%'
  },
  question: {
    textAlign: 'center'
  }
}))

export default React.memo(QABox)

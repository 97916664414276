export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type LaborReferentStepSelectedFragment = (
  { __typename?: 'LaborReferent' }
  & Pick<LaborReferent, '_id' | 'stepSelected'>
);

export type UpdateAnswerQuestionMutationVariables = Exact<{
  backgroundCheckId: Scalars['ID'];
  laborReferentId: Scalars['ID'];
  questionId: Scalars['ID'];
  answer: Scalars['String'];
}>;


export type UpdateAnswerQuestionMutation = (
  { __typename?: 'Mutation' }
  & { updateAnswerQuestion?: Maybe<(
    { __typename?: 'Question' }
    & Pick<Question, '_id' | 'statement' | 'answer'>
  )> }
);

export type FinishMutationVariables = Exact<{
  backgroundCheckId: Scalars['ID'];
}>;


export type FinishMutation = (
  { __typename?: 'Mutation' }
  & { finish?: Maybe<(
    { __typename?: 'BackgroundCheck' }
    & Pick<BackgroundCheck, '_id' | 'status'>
  )> }
);

export type GetBackgroundCheckStatusQueryVariables = Exact<{
  backgroundCheckId: Scalars['ID'];
}>;


export type GetBackgroundCheckStatusQuery = (
  { __typename?: 'Query' }
  & { getBackgroundCheck: (
    { __typename?: 'BackgroundCheck' }
    & Pick<BackgroundCheck, '_id' | 'status'>
  ) }
);

export type GetJobQueryVariables = Exact<{
  jobId: Scalars['ID'];
}>;


export type GetJobQuery = (
  { __typename?: 'Query' }
  & { getJob?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, '_id'>
    & { publications: Array<(
      { __typename?: 'Publication' }
      & Pick<Publication, '_id' | 'title' | 'confidentialCompany' | 'status'>
    )>, companyPublished?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, '_id' | 'name'>
      & { profile: (
        { __typename?: 'Profile' }
        & Pick<Profile, 'logo'>
      ) }
    )> }
  )> }
);

export type CandidateFragment = (
  { __typename?: 'Candidate' }
  & Pick<Candidate, '_id' | 'photo' | 'phone' | 'email' | 'firstName' | 'lastName' | 'fullName' | 'jobId'>
  & { curriculum?: Maybe<(
    { __typename?: 'Curriculum' }
    & Pick<Curriculum, 'fileName' | 'url' | 'type'>
  )>, education: Array<(
    { __typename?: 'Education' }
    & Pick<Education, '_id' | 'institutionName' | 'degree' | 'condition' | 'career' | 'academicArea' | 'startDate' | 'endDate' | 'description'>
  )>, experience: Array<(
    { __typename?: 'Experience' }
    & Pick<Experience, '_id' | 'jobPosition' | 'companyName' | 'hierarchy' | 'area' | 'location' | 'startDate' | 'endDate' | 'description'>
  )>, knowledge: Array<(
    { __typename?: 'Knowledge' }
    & Pick<Knowledge, '_id' | 'knowledgeName' | 'level'>
  )>, especialization: Array<(
    { __typename?: 'Especialization' }
    & Pick<Especialization, '_id' | 'studyingHere' | 'description' | 'especializationName' | 'especializationPlace' | 'startDate' | 'endDate'>
  )>, websites: Array<(
    { __typename?: 'EmailsPhonesResponse' }
    & Pick<EmailsPhonesResponse, '_id' | 'type' | 'value'>
  )> }
);

export type GetCandidateQueryVariables = Exact<{
  candidateId: Scalars['ID'];
}>;


export type GetCandidateQuery = (
  { __typename?: 'Query' }
  & { getCandidate: (
    { __typename?: 'Candidate' }
    & CandidateFragment
  ) }
);

export type VerifyUserQueryVariables = Exact<{ [key: string]: never; }>;


export type VerifyUserQuery = (
  { __typename?: 'Query' }
  & { getUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, '_id' | 'email' | 'firstName' | 'lastName' | 'photo'>
  )> }
);

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { getUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, '_id' | 'email' | 'firstName' | 'lastName' | 'photo'>
  )> }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & { logout?: Maybe<(
    { __typename?: 'Response' }
    & Pick<Response, 'success' | 'data'>
  )> }
);

export type BackgroundCheckFragment = (
  { __typename?: 'BackgroundCheck' }
  & Pick<BackgroundCheck, '_id' | 'jobId' | 'candidateId' | 'candidateTaskId' | 'status'>
  & { laborReferents: Array<(
    { __typename?: 'LaborReferent' }
    & LaborReferentFragment
  )> }
);

export type GetBackgroundCheckQueryVariables = Exact<{
  backgroundCheckId: Scalars['ID'];
}>;


export type GetBackgroundCheckQuery = (
  { __typename?: 'Query' }
  & { getBackgroundCheck: (
    { __typename?: 'BackgroundCheck' }
    & BackgroundCheckFragment
  ) }
);

export type LaborReferentFragment = (
  { __typename?: 'LaborReferent' }
  & Pick<LaborReferent, '_id' | 'experienceId' | 'stepSelected' | 'fullName' | 'email' | 'phone' | 'companyName' | 'jobPosition' | 'disablePayment'>
  & { questions: Array<(
    { __typename?: 'Question' }
    & Pick<Question, '_id' | 'statement' | 'answer'>
  )> }
);

export type UpdateLaborReferentMutationVariables = Exact<{
  backgroundCheckId: Scalars['ID'];
  laborReferentId: Scalars['ID'];
  input: LaborReferentInput;
}>;


export type UpdateLaborReferentMutation = (
  { __typename?: 'Mutation' }
  & { updateLaborReferent?: Maybe<(
    { __typename?: 'LaborReferent' }
    & Pick<LaborReferent, '_id' | 'experienceId' | 'fullName' | 'email' | 'phone' | 'companyName' | 'jobPosition'>
  )> }
);

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** Date custom scalar type */
  Date: any;
};












export type AdditionalEntityFields = {
  path?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  logout?: Maybe<Response>;
  updateLaborReferent?: Maybe<LaborReferent>;
  updateAnswerQuestion?: Maybe<Question>;
  finish?: Maybe<BackgroundCheck>;
};


export type MutationUpdateLaborReferentArgs = {
  backgroundCheckId: Scalars['ID'];
  laborReferentId: Scalars['ID'];
  input: LaborReferentInput;
};


export type MutationUpdateAnswerQuestionArgs = {
  backgroundCheckId: Scalars['ID'];
  laborReferentId: Scalars['ID'];
  questionId: Scalars['ID'];
  answer: Scalars['String'];
};


export type MutationFinishArgs = {
  backgroundCheckId: Scalars['ID'];
};

export type Query = {
  __typename?: 'Query';
  authorization: Response;
  getUser?: Maybe<User>;
  getBackgroundCheck: BackgroundCheck;
  getCandidate: Candidate;
  holaMundo?: Maybe<Scalars['String']>;
  getJob?: Maybe<Job>;
  isLoggedIn: Scalars['Boolean'];
};


export type QueryGetBackgroundCheckArgs = {
  backgroundCheckId: Scalars['ID'];
};


export type QueryGetCandidateArgs = {
  candidateId: Scalars['ID'];
};


export type QueryHolaMundoArgs = {
  name: Scalars['String'];
};


export type QueryGetJobArgs = {
  jobId: Scalars['ID'];
};

export type User = {
  __typename?: 'User';
  _id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
};

export type BackgroundCheckInput = {
  taskId: Scalars['ID'];
  jobId: Scalars['ID'];
  candidateId: Scalars['ID'];
  candidateTaskId: Scalars['ID'];
  krowderGroupId: Scalars['ID'];
  userId: Scalars['ID'];
  resultTaskParentId?: Maybe<Scalars['ID']>;
  status?: Maybe<BackgroundCheckStatus>;
};

export type LaborReferentInput = {
  experienceId?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  jobPosition?: Maybe<Scalars['String']>;
  refId?: Maybe<Scalars['ID']>;
};

export type QuestionInput = {
  statement?: Maybe<Scalars['String']>;
  answer?: Maybe<Scalars['String']>;
};

export type ExperienceInput = {
  _id: Scalars['ID'];
  jobPosition?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  hierarchy?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  workHere?: Maybe<Scalars['Boolean']>;
};

export enum BackgroundCheckStatus {
  Pending = 'pending',
  Doing = 'doing',
  Executed = 'executed',
  Deleted = 'deleted'
}

export enum LaborReferentStatusExecuted {
  Complete = 'COMPLETE',
  Incomplete = 'INCOMPLETE'
}

export type BackgroundCheck = {
  __typename?: 'BackgroundCheck';
  _id?: Maybe<Scalars['ID']>;
  taskId: Scalars['ID'];
  jobId: Scalars['ID'];
  candidateId: Scalars['ID'];
  candidateTaskId: Scalars['ID'];
  krowderGroupId: Scalars['ID'];
  status?: Maybe<BackgroundCheckStatus>;
  userId: Scalars['ID'];
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  laborReferents: Array<LaborReferent>;
  user?: Maybe<User>;
  hits?: Maybe<Scalars['Int']>;
};

export type LaborReferent = {
  __typename?: 'LaborReferent';
  _id?: Maybe<Scalars['ID']>;
  experienceId?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  jobPosition?: Maybe<Scalars['String']>;
  questions: Array<Question>;
  experience?: Maybe<Experience>;
  generated: Scalars['Boolean'];
  completed?: Maybe<Scalars['Boolean']>;
  disablePayment?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  statusExecuted?: Maybe<Scalars['String']>;
  stepSelected: Scalars['Int'];
};

export type Question = {
  __typename?: 'Question';
  _id?: Maybe<Scalars['ID']>;
  statement: Scalars['String'];
  answer: Scalars['String'];
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  refId: Scalars['ID'];
};

export type Experience = {
  __typename?: 'Experience';
  _id: Scalars['ID'];
  jobPosition?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  hierarchy?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  workHere?: Maybe<Scalars['Boolean']>;
};

export type Candidate = {
  __typename?: 'Candidate';
  _id: Scalars['ID'];
  curriculum?: Maybe<Curriculum>;
  education: Array<Education>;
  email?: Maybe<Scalars['String']>;
  experience: Array<Experience>;
  knowledge: Array<Knowledge>;
  especialization: Array<Especialization>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  jobId: Scalars['ID'];
  linkedin?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  websites: Array<EmailsPhonesResponse>;
};

export type Knowledge = {
  __typename?: 'Knowledge';
  _id: Scalars['ID'];
  knowledgeName?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
};

export type Especialization = {
  __typename?: 'Especialization';
  _id: Scalars['ID'];
  studyingHere?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  especializationName?: Maybe<Scalars['String']>;
  especializationPlace?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type Education = {
  __typename?: 'Education';
  _id: Scalars['ID'];
  institutionName?: Maybe<Scalars['String']>;
  degree?: Maybe<Scalars['String']>;
  condition?: Maybe<Scalars['String']>;
  career?: Maybe<Scalars['String']>;
  academicArea?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  studyingHere?: Maybe<Scalars['Boolean']>;
};



export type Response = {
  __typename?: 'Response';
  success: Scalars['Boolean'];
  data?: Maybe<Scalars['JSON']>;
};

export type Example = {
  __typename?: 'Example';
  _id: Scalars['ID'];
};

export type Curriculum = {
  __typename?: 'Curriculum';
  fileName?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type EmailsPhonesResponse = {
  __typename?: 'EmailsPhonesResponse';
  _id?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export enum PublicationStatus {
  Draft = 'draft',
  Published = 'published',
  Closed = 'closed',
  Filed = 'filed',
  Pending = 'pending'
}

export type Job = {
  __typename?: 'Job';
  _id: Scalars['ID'];
  publications: Array<Publication>;
  companyPublished?: Maybe<Company>;
};

export type Publication = {
  __typename?: 'Publication';
  _id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  confidentialCompany: Scalars['Boolean'];
  status: PublicationStatus;
};

export type Company = {
  __typename?: 'Company';
  _id: Scalars['ID'];
  name: Scalars['String'];
  profile: Profile;
};

export type Profile = {
  __typename?: 'Profile';
  logo?: Maybe<Scalars['String']>;
};

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { loader } from 'graphql.macro'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { Avatar, Button, Card, CardContent, IconButton, List, ListItem, ListItemAvatar, ListItemText, makeStyles, notify, TextField, Typography } from '@krowdy-ui/core'
import { AnimatePresence, motion } from 'framer-motion'
import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  Person as PersonIcon
} from '@material-ui/icons'
import clsx from 'clsx'
import { useMutation } from '@apollo/client'

import QuestionsStepper from './QuestionsStepper'
import { UpdateLaborReferentMutation, UpdateLaborReferentMutationVariables, LaborReferentFragment } from '__generated__/typescript-operations'
import { PaletteColor } from 'utils/theme'

import Referent from 'components/Referent'
import Progress from 'components/Progress'

interface CardReferentProps {
  index: number;
  laborReferent: LaborReferentFragment;
  total: number;
  className?: string;
  laborReferentIds: Array<Pick<LaborReferentFragment, '_id'> & { percent: number }>;
  disabled?: boolean;
}

const UPDATE_LABOR_REFERENT = loader('./apollo/updateLaborReferent.graphql')

const IconButtonMotion = motion.custom(IconButton)

const variants = {
  enter: {
    opacity: 1,
    y      : 0
  },
  exit: {
    opacity: 0,
    y      : '100%'
  }
}

const CardReferent: FC<CardReferentProps> = ({
  laborReferent,
  laborReferentIds,
  index,
  total,
  className,
  disabled
}) => {
  const classes = useStyles()
  const history = useHistory()
  const [ isEditMode, setIsEditMode ] = useState(false)
  const [ state, setState ] = useState(laborReferent)
  const { referentId, backgroundCheckId } = useParams<{referentId: string; backgroundCheckId: string}>()

  const selected = useMemo(() => referentId === laborReferent._id, [ laborReferent._id, referentId ])

  const percent = useMemo(() => (laborReferentIds || [])[index]?.percent || 0, [ index, laborReferentIds ])

  const [
    updateLaborReferent, { loading: loadingUpdateLaborReferent }
  ] = useMutation<UpdateLaborReferentMutation, UpdateLaborReferentMutationVariables>(
    UPDATE_LABOR_REFERENT
  )

  const _handleClickEdit = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    setIsEditMode(true)
  }, [])

  const _handleClickCancel = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    setState(laborReferent)
    setIsEditMode(false)
  }, [ laborReferent ])

  const _handleChange = useCallback(({ target: { name, value } }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setState(state => ({ ...state, [name]: value }))
  }, [])

  const _handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    updateLaborReferent({
      variables: {
        backgroundCheckId,
        input: {
          companyName: state.companyName,
          email      : state.email,
          fullName   : state.fullName,
          jobPosition: state.jobPosition,
          phone      : state.phone
        },
        laborReferentId: laborReferent._id!
      } })
      .then(({ data }) => {
        if(data)
          setState((state) => ({
            ...state,
            ...data.updateLaborReferent!
          }))
        notify.success('Tarea actualizada')
        setIsEditMode(false)
      })
      .catch((error) => {
        notify.error(error.message)
        setIsEditMode(false)
      })
  }

  const _handleChangeNextReferent = useCallback(() => {
    if(laborReferentIds)
      history.push(generatePath('/background-check/:backgroundCheckId/detail/:referentId', {
        backgroundCheckId,
        referentId: laborReferentIds[(index - 1 + laborReferentIds?.length) % laborReferentIds?.length]?._id as string
      }))
  }, [ backgroundCheckId, history, index, laborReferentIds ])

  const _handleChangePrevReferent = useCallback(() => {
    if(laborReferentIds)
      history.push(generatePath('/background-check/:backgroundCheckId/detail/:referentId', {
        backgroundCheckId,
        referentId: laborReferentIds[(index + 1 + laborReferentIds?.length) % laborReferentIds?.length]?._id as string
      }))
  }, [ backgroundCheckId, history, index, laborReferentIds ])

  const _handleClickTextField = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
  }, [])

  useEffect(() => {
    const verify = () => {
      for (let key of [ 'companyName', 'email', 'fullName', 'jobPosition', 'phone' ])
        if(state[key as keyof Pick<LaborReferentFragment, 'companyName' | 'email' | 'fullName' | 'jobPosition' | 'phone'>]) return true

      return false
    }
    if(!verify()) setIsEditMode(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Card
      className={clsx(classes.card, className)}
      hoverable={!referentId}
      key={laborReferent._id}>
      <div className={classes.cardHeader}>
        <AnimatePresence>
          {
            referentId && laborReferentIds.length > 1 ? (
              <IconButtonMotion
                animate='enter'
                exit='exit'
                initial='exit'
                onClick={_handleChangePrevReferent}
                size='small'
                transition={{ duration: 0.5 }}
                variants={variants}>
                <KeyboardArrowLeftIcon color='primary' fontSize='small' />
              </IconButtonMotion>
            ) : null
          }
        </AnimatePresence>
        <Typography className={classes.title} color='secondary' variant='body2'>
          Referente {index + 1}/{total}
        </Typography>
        <AnimatePresence>
          {
            referentId && laborReferentIds.length > 1 ? (
              <IconButtonMotion
                animate='enter'
                exit='exit'
                initial='exit'
                onClick={_handleChangeNextReferent}
                size='small'
                transition={{ duration: 0.5 }}
                variants={variants}>
                <KeyboardArrowRightIcon color='primary' fontSize='small' />
              </IconButtonMotion>
            ) : null
          }
        </AnimatePresence>
      </div>
      <CardContent className={classes.cardContent}>
        {isEditMode ? (
          <div>
            <List>
              <ListItem className={classes.listItem}>
                <ListItemAvatar className={classes.listItemAvatar}>
                  <Avatar className={classes.avatar} variant='circle'>
                    <PersonIcon className={classes.icon} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={(
                    <TextField
                      disabled={disabled}
                      // className={classes.textField}
                      fullWidth
                      InputProps={{
                        disableUnderline: true
                      }}
                      inputProps={{
                        className: classes.input
                      }}
                      name='fullName'
                      onChange={_handleChange}
                      onClick={_handleClickTextField}
                      placeholder='Nombre de referente'
                      size='small'
                      value={state.fullName} />
                  )} />
              </ListItem>
            </List>
            <TextField
              autoComplete='new-phone'
              className={classes.textField}
              disabled={disabled}
              fullWidth
              inputProps={{
                className: classes.input
              }}
              InputProps={{
                className       : classes.inputBase,
                disableUnderline: true
              }}
              name='phone'
              onChange={_handleChange}
              onClick={_handleClickTextField}
              placeholder='Teléfono'
              size='small'
              value={state.phone}
              variant='filled' />
            <TextField
              autoComplete='new-email'
              className={classes.textField}
              disabled={disabled}
              fullWidth
              inputProps={{
                className: classes.input
              }}
              InputProps={{
                className       : classes.inputBase,
                disableUnderline: true
              }}
              name='email'
              onChange={_handleChange}
              onClick={_handleClickTextField}
              placeholder='Email'
              size='small'
              value={state.email}
              variant='filled' />
            <TextField
              className={classes.textField}
              disabled={disabled}
              fullWidth
              inputProps={{
                className: classes.input
              }}
              InputProps={{
                className       : classes.inputBase,
                disableUnderline: true
              }}
              name='companyName'
              onChange={_handleChange}
              onClick={_handleClickTextField}
              placeholder='Nombre de compañía'
              size='small'
              value={state.companyName}
              variant='filled' />
            <TextField
              className={classes.textField}
              disabled={disabled}
              fullWidth
              inputProps={{
                className: classes.input
              }}
              InputProps={{
                className       : classes.inputBase,
                disableUnderline: true
              }}
              name='jobPosition'
              onChange={_handleChange}
              onClick={_handleClickTextField}
              placeholder='Puesto'
              size='small'
              value={state.jobPosition}
              variant='filled' />
          </div>
        ) : (
          <Referent
            companyName={laborReferent.companyName}
            email={laborReferent.email}
            fullName={laborReferent.fullName}
            jobPosition={laborReferent.jobPosition}
            phone={laborReferent.phone} />
        )}
        <div className={classes.actionButtons}>
          {isEditMode ? (
            <>
              <Button
                color='primary'
                disabled={disabled}
                onClick={_handleClickCancel}>
                  Cancelar
              </Button>
              <Button
                color='primary'
                disabled={loadingUpdateLaborReferent || disabled}
                onClick={_handleSubmit}
                variant='contained'>
                  Guardar
              </Button>
            </>
          ) : (
            <Button color='primary' disabled={disabled} onClick={_handleClickEdit}>Editar</Button>
          )}
        </div>
        <AnimatePresence>
          {selected ? (
            <motion.div
              animate='enter'
              exit='exit'
              initial='exit'
              transition={{ duration: 0.5 }}
              variants={variants}>
              <QuestionsStepper
                laborReferentId={laborReferent._id!}
                questions={laborReferent.questions}
                stepSelected={laborReferent.stepSelected} />
            </motion.div>
          ) : null}
          {
            referentId ? null : (
              <motion.div
                animate='enter'
                exit='exit'
                initial='exit'
                transition={{ duration: 0.5 }}
                variants={variants}>
                <div className={classes.progress}>
                  <Progress center check progress={percent} />
                </div>
              </motion.div>
            )
          }
        </AnimatePresence>
      </CardContent>
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  actionButtons: {
    display       : 'flex',
    justifyContent: 'flex-end'
  },
  avatar: {
    backgroundColor: (theme.palette.secondary as PaletteColor)[10],
    height         : 32,
    width          : 32
  },
  card: {
    height: '100%'
  },
  cardContent: {
    height       : 'calc(100vh - 192px)',
    overflowY    : 'auto',
    paddingBottom: 0,
    paddingTop   : 0
  },
  cardHeader: {
    alignItems    : 'center',
    display       : 'flex',
    height        : 48,
    justifyContent: 'center',
    paddingBottom : theme.spacing(1.5),
    paddingTop    : theme.spacing(1.5)
  },
  container: {
    display  : 'flex',
    flex     : 1,
    minHeight: 0,
    padding  : theme.spacing(1.5)
  },
  flex: {
    alignItems: 'center',
    display   : 'flex',
    height    : '100%'
  },
  icon: {
    color: (theme.palette.secondary as PaletteColor)[300]
  },
  input: {
    padding: theme.spacing(0)
  },
  inputBase: {
    backgroundColor: (theme.palette.secondary as PaletteColor)[10],
    borderRadius   : theme.shape.borderRadius,
    fontSize       : 12,
    padding        : theme.spacing(1)
  },
  listItem: {
    padding: 0
  },
  listItemAvatar: {
    marginTop: 0,
    minWidth : 48
  },
  progress: {
    display       : 'flex',
    justifyContent: 'center',
    marginTop     : theme.spacing(2.5)
  },
  textField: {
    marginBottom: theme.spacing(1)
  },
  title: {
    marginLeft : theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}), { name: 'CardReferent' })

export default CardReferent

import React, { ReactNode } from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { useLoginRequired } from 'hooks/useLoginRequired'

interface IProps extends RouteProps {
  children: ReactNode;
  // any other props that come into the component
}

const PrivateRoute: React.FC<IProps> = ({ children, ...rest }) => {
  useLoginRequired()

  return (
    <Route
      {...rest}
      render={() =>
        children
      } />
  )
}

export default PrivateRoute


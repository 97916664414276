import { ApolloClient } from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { disableFragmentWarnings } from 'graphql-tag'
import { loader } from 'graphql.macro'

import jstz from 'jstz'

import { LOCAL_STORAGE_KEYS } from 'utils/constants'
import { cache } from './cache'

const schema = loader('./schema.graphql')

disableFragmentWarnings()

const { REACT_APP_SERVER_URL, NODE_ENV } = process.env

const timezone = jstz.determine()

const client = new ApolloClient({
  cache,
  link: new BatchHttpLink({
    batchInterval: 50,
    headers      : {
      Authorization: `Bearer ${localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)}`,
      refreshToken : localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN),
      timezone     : timezone.name()
    },
    uri: `${REACT_APP_SERVER_URL}/graphql`
  }),
  typeDefs: schema,
  uri     : `${process.env.REACT_APP_SERVER_URL}/graphql`
})

declare global {
  interface Window { cache: any; }
}

if(NODE_ENV === 'development')
  window.cache = window.cache || client.extract()

export default client

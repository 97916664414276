import React, { useEffect } from 'react'

import { LOCAL_STORAGE_KEYS } from 'utils/constants'

import getQueryParams from 'utils/getQueryParams'

export default () => {
  useEffect(() => {
    const params = getQueryParams(window.location.search)
    localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, params.accessToken)
    localStorage.setItem(LOCAL_STORAGE_KEYS.USER_ID, params.iduser)
    localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, params.refreshToken)
    window.location.href = params.urlRedirect
  })

  return <div></div>
}

import React, { FC } from 'react'
// import Cookies from 'js-cookie'
// import { useSelector, useDispatch } from 'react-redux'
import { TopAppBar } from '@krowdy-ui/views'

import Loading from 'components/Loading'
import { makeStyles } from '@krowdy-ui/core'
import { useMutation, useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import { GetUserQuery, GetUserQueryVariables, LogoutMutation, LogoutMutationVariables } from '__generated__/typescript-operations'

// import { useComponentWillMount } from 'lib/hooks'

// import { getAuth, logout, setCookies, getUser } from 'reducers/user'

// const { NODE_ENV, REACT_APP_AUTH } = process.env
interface MainProps {
  children: React.ReactNode
}

const GET_USER = loader('./apollo/getUser.graphql')
const LOGOUT = loader('./apollo/logout.graphql')

const Main: FC<MainProps> = ({ children }) => {
  const classes = useStyles()
  const [ logout ] = useMutation<LogoutMutation, LogoutMutationVariables>(LOGOUT)
  const { data, loading } = useQuery<GetUserQuery, GetUserQueryVariables>(GET_USER)
  const _handleClickLogout = async () => {
    try {
      await logout()
      localStorage.clear()
      sessionStorage.clear()
    } catch (error) {
      // eslint-disable-next-line no-restricted-syntax
      console.log('Error closing session')
      throw error
    }
  }

  if(loading) return <Loading />

  const user = data?.getUser!

  return  (
    <div className={classes.root}>
      <TopAppBar
        apps={[
        ]}
        logo={{
          alt   : 'Logo',
          source: 'https://cdn.krowdy.com/auth/logobase.png'
        }}
        menuTopRight={[
        ]}
        onHandleLogout={_handleClickLogout}
        user={{
          firstName: user.firstName,
          lastName : user.lastName,
          photo    : user.photo as string
        }}
        userMenu={[
        ]} />
      {children}
    </div>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#E5E5E5',
    display        : 'flex',
    flexDirection  : 'column',
    height         : '100vh'
  }
}))

export default Main

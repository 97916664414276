import React, { FC } from 'react'
import { Divider, IconButton, makeStyles, Paper, Typography } from '@krowdy-ui/core'
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import { useQuery } from '@apollo/client'

import { loader } from 'graphql.macro'
import { GetJobQuery, GetJobQueryVariables } from '__generated__/typescript-operations'
interface HeaderJobProps {
  jobId: string,
  action?: React.ReactNode
}

const GET_JOB = loader('./apollo/getJob.graphql')

const variants = {
  enter: {
    opacity: 1,
    width  : 'auto',
    x      : 0
  },
  exit: {
    opacity: 0,
    width  : 0,
    x      : '-100%'
  }
}

const HeaderJob: FC<HeaderJobProps> = ({ jobId, action }) => {
  const classes = useStyles()
  const { referentId, backgroundCheckId } = useParams<{referentId: string; backgroundCheckId: string;}>()
  const history = useHistory()
  const { data: dataJob } = useQuery<GetJobQuery, GetJobQueryVariables>(GET_JOB, { variables: { jobId } })

  const _handleGoBack = () => {
    history.replace(generatePath('/background-check/:backgroundCheckId', {
      backgroundCheckId
    }))
  }

  return (
    <Paper className={classes.header} variant='outlined'>
      <div className={classes.flex}>
        <AnimatePresence>
          {
            referentId ? (
              <motion.div
                animate='enter'
                className={classes.flex}
                exit='exit'
                initial='exit'
                transition={{ duration: 0.5 }}
                variants={variants}>
                <IconButton color='primary' onClick={_handleGoBack} size='small'>
                  <ArrowBackIcon fontSize='small' />
                </IconButton>
                <Divider className={classes.marginLeft} flexItem orientation='vertical' />
              </motion.div>
            ) : null
          }
        </AnimatePresence>
        <Typography className={classes.marginLeft} variant='h6'>{dataJob?.getJob?.publications[0].title}</Typography>
      </div>
      <div className={classes.flex}>
        {action}
      </div>
    </Paper>
  )
}

const useStyles = makeStyles((theme) => ({
  flex: {
    alignItems: 'center',
    display   : 'flex',
    height    : '100%'
  },
  header: {
    alignItems    : 'center',
    borderRadius  : 0,
    display       : 'flex',
    height        : 56,
    justifyContent: 'space-between',
    padding       : theme.spacing(2)
  },
  marginLeft: {
    marginLeft: theme.spacing(1)
  }
}))

export default HeaderJob

import React, { FC, useCallback } from 'react'
import { makeStyles, Step, StepLabel, Stepper } from '@krowdy-ui/core'
import { CheckCircle as CheckCircleIcon, CheckCircleOutline as CheckCircleOutlineIcon } from '@material-ui/icons'
import { loader } from 'graphql.macro'

import { Question, LaborReferentStepSelectedFragment } from '__generated__/typescript-operations'

import produce from 'immer'
import { useApolloClient } from '@apollo/client'

interface QuestionsStepperProps {
  questions: Pick<Question, '_id' | 'answer' | 'statement'>[];
  stepSelected: number;
  laborReferentId: string;
}

interface StepIconComponentProps {
  active: boolean;
  completed: boolean;
}

const LABOR_REFERENT_STEP_SELECTED_FRAGMENT = loader('./apollo/laborReferentStepSelectedFragment.graphql')

const StepIconComponent: FC<StepIconComponentProps> = ({ active, completed }) => {
  if(active) return <CheckCircleOutlineIcon color={'primary'} />
  if(completed) return <CheckCircleIcon color={'primary'} />

  return <CheckCircleOutlineIcon color={'disabled'} />
}

const QuestionsStepper: FC<QuestionsStepperProps> = ({ questions, stepSelected, laborReferentId }) => {
  const classes = useStyles()
  const client = useApolloClient()

  const _handleChangeStep = useCallback((index: number) => () => {
    try {
      const params = {
        fragment: LABOR_REFERENT_STEP_SELECTED_FRAGMENT,
        id      : `LaborReferent:${laborReferentId}`
      }
      client.writeFragment({
        ...params,
        data: produce(client.readFragment(params), (draft: LaborReferentStepSelectedFragment) => {
          draft.stepSelected = index
        })
      })

      const questionElement = document.getElementById(`question-${questions[index]?._id}`)

      if(questionElement) {
        const parent = questionElement.parentNode as HTMLElement

        if(parent)
          parent.scrollTop = (questionElement as HTMLElement).offsetTop - 52

        questionElement.querySelector('textarea')?.focus()
      }
    } catch (error) {
      // eslint-disable-next-line no-restricted-syntax
      console.log('error', error)
      throw error
    }
  }, [ client, laborReferentId, questions ])

  return (
    <Stepper
      activeStep={stepSelected}
      className={classes.stepper}
      connector={<div className={classes.connector} />}
      orientation='vertical'>
      {questions.map(({ statement, _id, answer }, index) => (
        <Step completed={Boolean(answer)} key={_id} onClick={_handleChangeStep(index)}>
          <StepLabel
            classes={{ active: classes.active, label: classes.label, root: classes.labelRoot }}
            StepIconComponent={StepIconComponent}>
            {index + 1}. {statement}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

const useStyles = makeStyles((theme) => ({
  active: {

  },
  connector: {
    height: 12
  },
  label: {
    color: theme.palette.grey[700]
  },
  labelRoot: {
    '& $active': {
      color: theme.palette.primary.main
    },
    cursor: 'pointer'
  },
  stepper: {
    backgroundColor: 'transparent',
    marginTop      : theme.spacing(2.5),
    padding        : 0
  }
}), { name: 'QuestionsStepper' })

export default QuestionsStepper

import React, { FC } from 'react'
import PDFViewer from './PDFViewer'

interface CurriculumViewProps {
  url: string;
}

const CurriculumView: FC<CurriculumViewProps> = ({ url }) => {
  const urlArray = (url || '').split('.')
  const type = urlArray.pop()
  try {
    switch (type) {
      case 'pdf' :
        return  (
          <PDFViewer
            document={{ url }} />
        )
      case 'word':
      case 'docx':
      case 'doc':
        return (
          <iframe
            frameBorder={0}
            height='100%'
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${url}`}
            title='viewerCv-word'
            width='100%' />
        )
      case 'png':
      case 'jpg':
      case 'jpeg':
        return (
          <iframe
            frameBorder={0}
            height='100%'
            src={url}
            title='viewerCv-image'
            width='100%' />
        )

      default:
        // return (<Empty
        //   label='Cv invalido, no se puede visualizar'
        //   src={EMPTY_CONTENT_URL} />
        // )
        return <div>Cv invalido, no se puede visualizar</div>
    }
  } catch (error) {
    // return (<Empty
    //   label='Cv invalido, no se puede visualizar'
    //   src={EMPTY_CONTENT_URL} />
    // )
    return <div>Cv invalido, no se puede visualizar</div>
  }
}

export default CurriculumView

import React, { FC } from 'react'
import { Avatar, Divider, List, ListItem, ListItemAvatar, ListItemText, makeStyles, Typography } from '@krowdy-ui/core'
import { Business as BusinessIcon, Person as PersonIcon } from '@material-ui/icons'
import { PaletteColor } from 'utils/theme'
import { Maybe } from '__generated__/typescript-operations'

interface ReferentProps {
  fullName?: Maybe<string>;
  phone?: Maybe<string>;
  email?: Maybe<string>;
  companyName?: Maybe<string>;
  jobPosition?: Maybe<string>;
}

const Referent: FC<ReferentProps> = ({
  fullName,
  phone,
  email,
  companyName,
  jobPosition
}) => {
  const classes = useStyles()

  return (
    <List className={classes.root}>
      <ListItem alignItems='flex-start' className={classes.listItem}>
        <ListItemAvatar className={classes.listItemAvatar}>
          <Avatar className={classes.avatar} variant='circle'>
            <PersonIcon className={classes.icon} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          className={classes.listItemText}
          primary={
            <Typography color='secondary' component='span' variant='h5'>{fullName}</Typography>
          }
          secondary={
            <>
              <Typography
                className={classes.span}
                color='textPrimary'
                component='span'
                variant='body2'>
                {phone}
              </Typography>
              <Typography
                className={classes.span}
                color='textPrimary'
                component='span'
                variant='body2'>
                {email}
              </Typography>
            </>
          } />
      </ListItem>
      <Divider />
      <ListItem alignItems='flex-start' className={classes.listItem}>
        <ListItemAvatar className={classes.listItemAvatar}>
          <Avatar className={classes.avatar} variant='circle'>
            <BusinessIcon className={classes.icon} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          className={classes.listItemText}
          primary={
            <Typography color='secondary' component='span' variant='h6'>{companyName}</Typography>
          }
          secondary={
            <Typography
              color='textPrimary'
              component='span'
              variant='body2'>
              {jobPosition}
            </Typography>
          } />
      </ListItem>
      <Divider />
    </List>
  )
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: (theme.palette.secondary as PaletteColor)[10],
    height         : 32,
    width          : 32
  },
  card: {
    width: 320
  },
  cardContent: {
    paddingBottom: 0,
    paddingTop   : 0
  },
  flex: {
    alignItems: 'center',
    display   : 'flex',
    height    : '100%'
  },
  header: {
    alignItems    : 'center',
    borderRadius  : 0,
    display       : 'flex',
    height        : 56,
    justifyContent: 'space-between',
    padding       : theme.spacing(2)
  },
  icon: {
    color: (theme.palette.secondary as PaletteColor)[300]
  },
  listItem: {
    padding: `${theme.spacing(1.5)}px 0`
  },
  listItemAvatar: {
    marginTop: 0,
    minWidth : 48
  },
  listItemText: {
    marginTop: 0
  },
  marginLeft: {
    marginLeft: theme.spacing(1)
  },
  root: {

  },
  span: {
    display: 'block'
  }
}), { name: 'Referent' })

export default React.memo(Referent)

import { InMemoryCache, ReactiveVar, makeVar } from '@apollo/client'

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    LaborReferent: {
      fields: {
        stepSelected: {
          read(parent) {
            return parent || 0
          }
        }
      }
    },
    Query: {
      fields: {
        isLoggedIn: {
          read() {
            return isLoggedInVar()
          }
        }
      }
    }
  }
})

/**
 * Set initial values when we create cache variables.
 */

export const isLoggedInVar: ReactiveVar<Boolean> = makeVar<Boolean>(false)


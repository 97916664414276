import React, { FC, useCallback, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import { makeStyles } from '@krowdy-ui/core'
import { ProfileCandidate } from '@krowdy-ui/views'
import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import { GetCandidateQuery, GetCandidateQueryVariables } from '__generated__/typescript-operations'
import { AnimatePresence, motion } from 'framer-motion'

import 'dayjs/locale/es'
import CurriculumDrawer from 'components/CurriculumDrawer'

dayjs.locale('es')

const Constants = {
  candidateBarWidth   : 33,
  candidateDrawerWidth: 300,
  top                 : 64
}

const variants = {
  closed: { opacity: 1, overflow: 'hidden', width: 0, x: 320 },
  open  : { opacity: 1, overflow: 'visible', width: 640, x: -640 }
}

interface ProfileProps {
  candidateId: string
}

const GET_CANDIDATE = loader('./apollo/getCandidate.graphql')

const Profile: FC<ProfileProps> = ({ candidateId }) => {
  const classes = useStyles()
  const [ openCV, setOpenCV ] = useState(false)
  const { data, loading } = useQuery<GetCandidateQuery, GetCandidateQueryVariables>(GET_CANDIDATE, { variables: { candidateId } })

  const _handleClickPreviewCV = useCallback(() => {
    setOpenCV(prev => !prev)
  }, [])

  const candidate = useMemo(() => {
    if(data && !loading) {
      const { getCandidate: candidate } = data!

      return {
        ...candidate,
        candidateId: candidate._id,
        curriculum : candidate.curriculum?.url || '',
        education  : (candidate.education || []).map((education) => ({
          ...education,
          endDate  : education.endDate && dayjs(education.endDate || '').format('MMMM YYYY'),
          startDate: education.startDate && dayjs(education.startDate || '').format('MMMM YYYY')
        })),
        email     : candidate.email || '',
        experience: (candidate.experience || []).map((experience) => ({
          ...experience,
          endDate  : experience.endDate && dayjs(experience.endDate || '').format('MMMM YYYY'),
          startDate: experience.startDate && dayjs(experience.startDate || '').format('MMMM YYYY')
        })),
        firstName: candidate.firstName || '',
        fullName : candidate.fullName || '',
        lastName : candidate.lastName || '',
        nameTask : '',
        phone    : candidate.phone as string,
        photo    : candidate.photo as string,
        website  : candidate.websites?.length ? candidate.websites[0]?.value || undefined : undefined
      }
    }

    return undefined
  }, [ data, loading ])

  const _handleToggleCV = useCallback(() => {
    setOpenCV(prev => !prev)
  }, [])

  const open = useMemo(() =>
    Boolean(candidate?.curriculum && openCV)
  , [ candidate?.curriculum, openCV ])

  return (
    <div className={classes.profile}>
      <AnimatePresence>
        {
          open ? (
            <motion.div
              animate={'open'}
              className={classes.container}
              exit='closed'
              initial='closed'
              transition={{ duration: .5, stiffness: 35, type: 'spring' }}
              variants={variants}>
              <CurriculumDrawer  candidateCV={candidate?.curriculum} onToggleCV={_handleToggleCV} />
            </motion.div>
          ) : null
        }
      </AnimatePresence>
      <div className={classes.profilerContainer}>
        <ProfileCandidate
          candidate={candidate}
          classes={{
            drawerSubHeaderContent: classes.drawerSubHeaderContent
          }}
          disableSubHeaderTitle
          onClickPreviewCV={_handleClickPreviewCV} />
      </div>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: 'white',
    display        : 'flex',
    flexDirection  : 'column',
    height         : '100%',
    opacity        : 0,
    position       : 'absolute',
    width          : 0,
    zIndex         : 1000
  },
  docked: {
    width: 0
  },
  drawerPaperCV: {
    // borderLeft: 0,
    boxShadow: '0px 12px 17px rgba(0, 39, 102, 0.07), 0px 5px 22px rgba(0, 39, 102, 0.06), 0px 7px 8px rgba(0, 39, 102, 0.1)',
    // ,
    height   : '100%',
    position : 'absolute',
    right    : Constants.candidateBarWidth + Constants.candidateDrawerWidth,
    top      : 0,
    // top   : Constants.top,
    width    : 600,
    zIndex   : 1
  },
  drawerPaperCV2: {
    height: `calc(100% - ${Constants.top}px)`,
    right : Constants.candidateBarWidth + Constants.candidateDrawerWidth,
    top   : 'initial',
    width : 600,
    zIndex: 1
  },
  drawerRoot: {

  },
  drawerSubHeaderContent: {
    padding: 0
  },
  profile: {
    backgroundColor: 'white',
    height         : '100%',
    position       : 'relative',
    zIndex         : 100
  },
  profilerContainer: {
    backgroundColor: 'white',
    height         : 'calc(100vh - 64px)',
    overflow       : 'auto',
    position       : 'relative',
    zIndex         : 1000
  }
}), { name: 'Profile' })

export default Profile
